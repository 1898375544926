/* eslint no-useless-computed-key: 0 */
/* eslint no-undef: 0 */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  selectRoot,
  resetSubmissions,
  saveSubmission,
  selectError,
  Errors,
  getSubmissions,
} from "react-formio";
import { i18next } from "../../../i18n";
import Form from "../../../containers/Form";
import LogMessage from "../../../containers/LogFile";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import BottomControlButtons from "../../../containers/BottomControlButtons/BottomControlButtons";
import routeService from "../../../services/routeService";
import Message from "../../../containers/Message";
import {
  UserMessages,
  Forms,
  PageTexts,
  AppConfig,
  OfflinePluginConfig,
} from "../../../config";
import { createDateRange, signatureOffline } from "../../../utils";
import FormioOfflineProject from "formio-plugin-offline";
import { isGpidSpecific, isGpidSpecificApprover } from "./helper";
const offlinePlugin = FormioOfflineProject.getInstance(
  AppConfig.projectUrl,
  AppConfig.projectUrl,
  OfflinePluginConfig
);

const View = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      tabsLength: 0,
      stateSubmission: {
        submission: {},
      },
      isSignature: false,
      isButtonLoading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { signature } = nextProps;

    const gpidSpecificView = isGpidSpecific(
      nextProps.form.form,
      nextProps.approverFlowCheck
    );
    const gpidSpecificApproverView = isGpidSpecificApprover(
      nextProps.form.form,
      nextProps.approverFlowCheck
    );

    if (
      signature.submission.data &&
      signature.submission._id !== prevState.stateSubmission.submission._id &&
      signature.submission.data.userSignature &&
      (!gpidSpecificView ||
        (gpidSpecificView &&
          signature.submission.data.gpid === nextProps.auth.user.data.gpid)) &&
      (!gpidSpecificApproverView ||
        (gpidSpecificApproverView &&
          nextProps.filters.trainerForm !== undefined &&
          nextProps.filters.trainerForm !== "" &&
          signature.submission.data.gpid === nextProps.filters.trainerForm))
    ) {
      return {
        ...prevState,
        stateSubmission: signature,
      };
    }

    return prevState;
  }

  onChangeHandler = (change) => {
    const { filters } = this.props;

    if (
      !this.state.isSignature &&
      change.data &&
      !!change.data.userSignature &&
      change.changed &&
      !change.changed.flags.fromSubmission
    ) {
      this.setState({ isSignature: true });
    }

    if (this.state.isSignature && change.changed && !change.changed.value) {
      this.setState({ isSignature: false });
    }

    if (change.changed && change.state !== "submitted") {
      this.setState((prevState) => {
        if (!change.data.userSignature && !change.data.approverSignature) {
          return prevState;
        }

        return {
          stateSubmission: {
            ...prevState.stateSubmission,
            submission: {
              ...prevState.stateSubmission.submission,
              data: {
                ...change.data,
                ...filters,
                plant: `${filters.plant}`,
              },
            },
          },
        };
      });
    }
  };

  onSubmitHandler = () => {
    const {
      form: { form },
      onSubmit,
      filters,
      signatures: { submissions: signatures },
      auth: { user },
      approverFlowCheck,
    } = this.props;
    const {
      stateSubmission: { submission },
    } = this.state;
    let date = filters.date.split("T")[0];
    date += "T00:00:00";
    const submissionObject = {
      data: {
        ...submission.data,
        formId: form._id,
        line: filters.line,
        plant: `${filters.plant}`,
        shift: filters.shift,
        date: date,
      },
      state: "submitted",
    };
    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);
    const gpidSpecificApproverView = isGpidSpecificApprover(
      form,
      approverFlowCheck
    );
    if (signatures.length !== 0) {
      const signature = signatures
        .filter(
          (signature) =>
            signature.data.formId === form._id &&
            signature.data.formStatus === "Pending approval" &&
            signature.data.shift === filters.shift &&
            signature.data.line === filters.line &&
            parseInt(signature.data.plant) === filters.plant &&
            signature.data.date.includes(filters.date.split("T")[0]) &&
            (!gpidSpecificView ||
              (gpidSpecificView && signature.data.gpid === user.data.gpid)) &&
            (!gpidSpecificApproverView ||
              (gpidSpecificApproverView &&
                filters.trainerForm !== undefined &&
                filters.trainerForm !== "" &&
                signature.data.gpid === filters.trainerForm))
        )
        .map((signature) => {
          return signature;
        });
      if (signature.length !== 0) {
        submissionObject._id = signature[0]._id;
        submissionObject.data.formStatus = signature[0].data.formStatus;
        submissionObject.data.gpid = signature[0].data.gpid;
      }
    }
    // Check if there is signature in offline queue and show it while offline
    if (
      offlinePlugin.offline &&
      offlinePlugin.submissionQueue.length &&
      (submissionObject.data.formStatus === "" ||
        submissionObject.data.formStatus === undefined)
    ) {
      const offlineSignature = offlinePlugin.submissionQueue.find(
        (offlineSubmission) =>
          offlineSubmission.request.data &&
          offlineSubmission.request.data.data.formId === form._id &&
          (offlineSubmission.request.form === Forms.Signature.id ||
            offlineSubmission.request.data.data.userSignature ||
            offlineSubmission.request.data.data.userSignature === "") &&
          offlineSubmission.request.data.data.line === filters.line &&
          parseInt(offlineSubmission.request.data.data.plant) ===
            filters.plant &&
          offlineSubmission.request.data.data.shift === filters.shift &&
          (!gpidSpecificView ||
            (gpidSpecificView &&
              offlineSubmission.request.data.data.gpid === user.data.gpid)) &&
          (!gpidSpecificApproverView ||
            (gpidSpecificApproverView &&
              filters.trainerForm !== undefined &&
              filters.trainerForm !== "" &&
              offlineSubmission.request.data.data.gpid ===
                filters.trainerForm)) &&
          offlineSubmission.request.data.data.date.includes(
            filters.date.split("T")[0]
          )
      );

      if (
        offlineSignature &&
        offlineSignature.request.data.data.userSignature
      ) {
        submissionObject._id = offlineSignature.request._id;
        submissionObject.data.formStatus =
          offlineSignature.request.data.formStatus;
        submissionObject.data.gpid = offlineSignature.request.data.gpid;
      }
    }

    submissionObject.data.formName = form.title.trim();
    //GPID issue fixed added for core 08 form
    // submissionObject.data.gpid=submissionObject.data.formStatus === undefined? user.data.gpid:submissionObject.data.gpid;
    submissionObject.data.gpid =
      submissionObject.data.formStatus === undefined ||
      !submissionObject.data.gpid
        ? user.data.gpid
        : submissionObject.data.gpid;
    submissionObject.data.formStatus =
      submissionObject.data.formStatus !== undefined &&
      submissionObject.data.formStatus === "Pending approval"
        ? "Approved"
        : "Pending approval";
    if (submissionObject.data.formStatus === "Approved") {
      submissionObject.data.approverGpid = user.data.gpid;
    }
    this.formLoadWait();
    onSubmit(submissionObject);
    this.setState({ isSignature: false });
  };

  formLoadWait = () => {
    this.setState({
      isButtonLoading: true,
    });

    this.interval = setTimeout(() => {
      this.setState({
        isButtonLoading: false,
      });
    }, 5000); //this will enable button after 5 seconds you can change time here.
  };
  componentWillUnmount() {
    delete this.webform;
  }

  render() {
    const {
      onSubmit,
      errors,
      form: { form, isActive },
      languageParams: { language },
      match: {
        params: { formId },
      },
      submissions,
      signature,
      filters,
      location,
      approverFlowCheck,
      auth,
    } = this.props;

    const {
      stateSubmission: { submission },
      isSignature,
      isButtonLoading,
    } = this.state;

    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);
    const gpidSpecificApproverView = isGpidSpecificApprover(
      form,
      approverFlowCheck
    );
    const specificGpid = gpidSpecificView
      ? auth.user.data.gpid
      : gpidSpecificApproverView
      ? filters.trainerForm
      : undefined;

    let isSignatureFromSubmission =
      (signature.submission.data &&
        !!signature.submission.data.formStatus &&
        signature.submission.data.formStatus !== "" &&
        (!gpidSpecificView ||
          (gpidSpecificView &&
            signature.submission.data.gpid === auth.user.data.gpid))) ||
      false;
    if (offlinePlugin.submissionQueue.length) {
      isSignatureFromSubmission = signatureOffline(
        form._id,
        filters,
        specificGpid
      );
    }

    if (
      approverFlowCheck &&
      approverFlowCheck.approverView &&
      isSignatureFromSubmission
    ) {
      isSignatureFromSubmission =
        !!signature.submission.data &&
        !!signature.submission.data.formStatus &&
        signature.submission.data.formStatus === "Approved" &&
        (!gpidSpecificApproverView ||
          (gpidSpecificApproverView &&
            filters.trainerForm !== undefined &&
            filters.trainerForm !== "" &&
            signature.submission.data.gpid === filters.trainerForm));
    }

    const formatDate = (isoString) => {
      const date = new Date(isoString);
      if (isNaN(date.getTime())) {
        return '';
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
   
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
   
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    /*Added shift level check to show draft error for signature only if any records of that shift have drafts-Applicable for Day level forms-Starts  */

    const pageArr = [];
    const shiftLevelSubmissions = submissions.submissions
      .filter(
        (item) =>
          item.metadata &&
          item.metadata.recordType &&
          item.metadata.recordType.includes("commonForShift") &&
          item.data &&
          item.data.shift &&
          item.data.shift === filters.shift
      )
      .map((item) => {
        if (pageArr[item.metadata.viewPage]) {
          pageArr[item.metadata.viewPage] += 1;
          return;
        }
        pageArr[item.metadata.viewPage] = 1;

        return item;
      });
    let conflictedShift = false;
    if (shiftLevelSubmissions.length >= 2) {
      for (const pageNo in pageArr) {
        if (pageArr[pageNo] >= 2) {
          conflictedShift = true;
        }
      }
    }
    let isSignatureAllowed = !submissions.submissions.some(
      (submission) =>
        submission.state === "draft" &&
        submission.data.shift === filters.shift &&
        (!gpidSpecificView ||
          (gpidSpecificView && submission.data.gpid === auth.user.data.gpid))
    );
    if (isSignatureAllowed && conflictedShift) {
      isSignatureAllowed = false;
    }
    /*Added shift level check to show draft error for signature only if any records of that shift have drafts-Applicable for Day level forms-Ends  */
    const isReadOnly = !isSignatureAllowed || isSignatureFromSubmission;
    const options = { ...this.props.options, readOnly: isReadOnly };
    let shiftSubmissions = {};
    if (submissions.submissions.length !== "0") {
      shiftSubmissions = submissions.submissions
        .filter((item) => item.data && item.data.shift === filters.shift)
        .map((item) => {
          return item;
        });
    }
    const isEmptySection = shiftSubmissions.length === 0;
    const primaryButtonText =
      approverFlowCheck && approverFlowCheck.approverView
        ? PageTexts.APPROVE_SHIFT
        : PageTexts.SUBMIT_AND_END_SHIFT;
    const primaryButtonAction = this.onSubmitHandler;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    if (isActive || signature.isActive) {
      return (
        <div className="pep-c-loader">
          <div className="pep-c-iload"></div>
        </div>
      );
    }

    const submissionObject = {};
    if (signature.submission.data) {
      submissionObject.submission = submission;
    }

    // Check if there is signature in offline queue and show it while offline
    if (offlinePlugin.offline && offlinePlugin.submissionQueue.length) {
      const offlineSignature = offlinePlugin.submissionQueue.find(
        (offlineSubmission) =>
          offlineSubmission.request.data &&
          offlineSubmission.request.data.data.formId === formId &&
          (offlineSubmission.request.form === Forms.Signature.id ||
            offlineSubmission.request.data.data.userSignature ||
            offlineSubmission.request.data.data.userSignature === "") &&
          offlineSubmission.request.data.data.line === filters.line &&
          parseInt(offlineSubmission.request.data.data.plant) ===
            filters.plant &&
          offlineSubmission.request.data.data.shift === filters.shift &&
          offlineSubmission.request.data.data.date.includes(
            filters.date.split("T")[0]
          ) &&
          (specificGpid === undefined ||
            offlineSubmission.request.data.data.gpid === specificGpid)
      );

      if (
        offlineSignature &&
        offlineSignature.request.data.data.userSignature
      ) {
        submissionObject.submission = offlineSignature.request.data;
      }
    }

    // // {approverFlowCheck && approverFlowCheck.approverView ? (
    //   <>
    //   <div className="fld-name">
    //     Filling Date: {formatDate(signature?.submission?.data?.date)}
    //     <br></br>
    //     Submitted Date: {formatDate(signature?.submission?.created)}
    //     <>
    //     {signature?.submission?.data?.formStatus === "Approved" &&  signature?.submission?.data?.approverSignature !== ""? (
    //       <>
    //         <br></br>
    //         Approved Date: {formatDate(signature?.submission?.modified)}
    //       </>
    //     ):null}
    //     </>
    //   </div>
    //  </>) : (<></>)}
    return (
      <div className="pep-sig-container">
        {isSignatureFromSubmission ? (
          submissionObject &&
          submissionObject.submission &&
          submissionObject.submission.data &&
          !!submissionObject.submission.data.formStatus &&
          submissionObject.submission.data.formStatus === "Pending approval" ? (
            <Message type={"info"} text={UserMessages.SHIFT_PENDING_APPROVAL} />
          ) : (
            <Message type={"info"} text={UserMessages.SHIFT_APPROVED} />
          )
        ) : isSignatureAllowed ? (
          isEmptySection ? (
            <Message type={"info"} text={UserMessages.RECORDS_NOT_ADDED} />
          ) : !(approverFlowCheck && approverFlowCheck.approverView) ? (
            <Message
              type={"info"}
              text={UserMessages.SHIFT_INSTRUCTIONS_TEXT}
            />
          ) : (
            <Message
              type={"info"}
              text={UserMessages.SHIFT_APPROVE_INSTRUCTIONS_TEXT}
            />
          )
        ) : conflictedShift ? (
          <Message
            type={"danger"}
            text={UserMessages.SHIFT_DUPLICATE_RECORD_ALERT}
          />
        ) : (
          <Message
            type={"danger"}
            text={UserMessages.SHIFT_DRAFT_RECORD_ALERT}
          />
        )}
        <div className="pep-signature">
          
          <div className="fld-name">
            {i18next.t("Signature", { title: i18next.t(form.title) })}
          </div>
          <Errors errors={errors} />
          <div className="pep-sig-form">
            <Form
              src={Forms.Signature.url}
              options={{
                ...{
                  template: "bootstrap",
                  iconset: "fa",
                  language: `${language}`,
                },
                ...options,
              }}
              onChange={this.onChangeHandler}
              onSubmit={onSubmit}
              ref={(instance) => {
                if (instance) {
                  instance.createPromise.then(() => {
                    this.webform = instance.formio;
                    const properties = { ...this.webform._form.properties };

                    //  Hide approver submission field if regular user or no user signature present
                    this.webform._form.properties.isHidden = !(
                      approverFlowCheck && approverFlowCheck.approverView
                    );

                    if (
                      this.webform &&
                      this.webform.component &&
                      this.webform.component.components
                    ) {
                      this.webform.component.components[3].disabled =
                        approverFlowCheck && approverFlowCheck.approverView;
                    }

                    if (typeof properties.isHidden === "undefined") {
                      this.webform.rebuild();
                    }
                  });
                }
              }}
              {...submissionObject}
            />
          </div>
          {!isSignatureAllowed ||
            isSignatureFromSubmission ||
            !isSignature ||
            true}
          <BottomControlButtons
            secondaryButtonText={i18next.t(PageTexts.BACK)}
            primaryButtonText={primaryButtonText}
            secondaryButtonPath={
              form.components[0].properties &&
              form.components[0].properties.display === "commonForShift"
                ? routeService.getPagePath.formPage(language, formId, 0)
                : routeService.getPagePath.submission(language, formId)
            }
            isReject={
              approverFlowCheck &&
              approverFlowCheck.approverView &&
              !isSignatureFromSubmission
            }
            primaryButtonAction={primaryButtonAction}
            isPrimaryButtonLoading={isButtonLoading}
            isRejectButtonPath={routeService.getPagePath.deleteSubmission(
              language,
              Forms.Signature.id,
              signature.submission._id,
              currentPage,
              "reject"
            )}
            isPrimaryButtonDisabled={
              !isSignatureAllowed || isSignatureFromSubmission
            }
            isSectionEmpty={isEmptySection}
            fromSignatureForm={true}
          />
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    submissions: selectRoot("submissions", state),
    form: selectRoot("form", state),
    auth: selectRoot("auth", state),
    errors: [selectError("form", state), selectError("submission", state)],
    filters: selectRoot("filters", state),
    languageParams: selectRoot("languageParams", state),
    signature: selectRoot("signature", state),
    signatures: selectRoot("signatures", state),
    userRoles: selectRoot("userRoles", state),
    approverFlowCheck: selectRoot("approverFlowCheck", state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const language = routeService.getLanguageFromRoute();
  return {
    onSubmit: (submission) => {
      dispatch(
        saveSubmission("submission", submission, Forms.Signature.id, (err) => {
          if (!err) {
            const range = createDateRange(submission.data.date);

            dispatch(
              getSubmissions(
                "signatures",
                1,
                {
                  ["data.date__gte"]: range.from,
                  ["data.date__lte"]: range.to,
                  ["data.line"]: submission.data.line,
                  ["data.plant"]: submission.data.plant,
                  ["data.shift"]: submission.data.shift,
                  ["data.formId"]: submission.data.formId,
                },
                Forms.Signature.id
              )
            );

            dispatch(resetSubmissions("submission"));
            LogMessage.info(submission, "SignatureForm.js- SaveSubmission");

            dispatch(
              push(
                routeService.getPagePath.signature(
                  language,
                  ownProps.match.params.formId
                )
              )
            );
          }
        })
      );
    },
  };
};
View.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  filters: PropTypes.object,
  match: PropTypes.object,
  otherformData: PropTypes.object,
  hideComponents: PropTypes.object,
  errors: PropTypes.any,
  options: PropTypes.object,
  languageParams: PropTypes.object,
  submission: PropTypes.object,
  submissions: PropTypes.object,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  signature: PropTypes.object,
  signatures: PropTypes.object,
  approverFlowCheck: PropTypes.shape({
    approverView: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
