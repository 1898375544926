/* eslint no-useless-computed-key: 0 */
/* eslint no-undef: 0 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import qs from "qs";
import FormioUtils from "formiojs/utils";
import PropTypes from "prop-types";
import {
  getSubmissions,
  selectRoot,
  selectError,
  Errors,
  getComponentDefaultColumn,
  setColumnsWidth,
} from "react-formio";
import SubmissionGrid from "../../../../containers/SubmissionGrid";
import FormioOfflineProject from "formio-plugin-offline";

import { i18next } from "../../../../i18n";
import BottomControlButtons from "../../../../containers/BottomControlButtons/BottomControlButtons";
import Legend from "../../../../containers/Legend";
import routeService from "../../../../services/routeService";
import {
  getQueryObject,
  signatureOffline,
  getSubmissionDefaultQuery,
  normalizeDataSetCategory,
} from "../../../../utils";
import {
  AppConfig,
  Forms,
  PageTexts,
  OfflinePluginConfig,
} from "../../../../config";
import {
  startSubmissionsDequeuing,
  finishSubmissionsDequeuing,
  setApproverFlow,
  setFiltersValue,
} from "../../Actions";
import { isGpidSpecific, isGpidSpecificApprover } from "../helper";
import SummaryGrid from "../../../../containers/SummaryGrid";

import QuaggaBarCode from "../../../../containers/BarcodePicker/QuaggaBarCode";

/* This class handles the submissions for the preview screen for a hourly tabs
 */

const offlinePlugin = FormioOfflineProject.getInstance(
  AppConfig.projectUrl,
  AppConfig.projectUrl,
  OfflinePluginConfig
);

const isOfflineSubmissions = (formId, filters) =>
  offlinePlugin.submissionQueue.some((offlineSubmission) => {
    return (
      (offlineSubmission.request.form === formId &&
        offlineSubmission.request.data !== null &&
        offlineSubmission.request.data.data.line === filters.line &&
        parseInt(offlineSubmission.request.data.data.plant) === filters.plant &&
        offlineSubmission.request.data.data.shift === filters.shift &&
        offlineSubmission.request.data.data.date.includes(
          filters.date.split("T")[0]
        )) ||
      // Check if offline signature belongs this form
      (offlineSubmission.request.form === Forms.Signature.id &&
        offlineSubmission.request.data !== null &&
        offlineSubmission.request.data.data.formId === formId) ||
      // Check if offline record is DELETE request
      (offlineSubmission.request.form === formId &&
        offlineSubmission.request.method === "DELETE")
    );
  });

const List = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        date: "",
        line: "",
        plant: "",
        shift: "",
      },
      legendItems: [
        {
          title: "Draft Records",
          color: "#ffdccd",
        },
      ],
      conflictItems: [
        {
          title:
            "Multiple Shift Records Exists.Please delete the unwanted record.",
          color: "#FF0000",
        },
      ],
      shiftItems: [
        {
          title: "Shift Records",
          color: "#FF0000",
        },
      ],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { formId },
      },
      filters,
      location,
    } = this.props;
    const query = getQueryObject(filters, { ["data.formId"]: formId });
    this.props.getSignaturesData(1, query);
    if (location.search.includes("new")) {
      this.props.getNewSubmission();
    }

    window.addEventListener("online", this.onlineListener);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.onlineListener);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.filters.date !== prevState.filters.date ||
        nextProps.filters.shift !== prevState.filters.shift ||
        nextProps.filters.line !== prevState.filters.line ||
        nextProps.filters.fromDate !== prevState.filters.fromDate ||
        nextProps.filters.formNameCsv !== prevState.filters.formNameCsv ||
        nextProps.filters.toDate !== prevState.filters.toDate ||
        nextProps.filters.apprLine !== prevState.filters.apprLine ||
        nextProps.filters.searchHome !== prevState.filters.searchHome ||
        nextProps.filters.frmDateFilter !== prevState.filters.frmDateFilter ||
        nextProps.filters.toDateFilter !== prevState.filters.toDateFilter ||
        nextProps.filters.searchForm !== prevState.filters.searchForm ||
        nextProps.filters.submitter !== prevState.filters.submitter ||
        nextProps.filters.formName !== prevState.filters.formName ||
        nextProps.filters.formShift !== prevState.filters.formShift ||
        nextProps.filters.approvalStatus !== prevState.filters.approvalStatus ||
        nextProps.filters.plant !== prevState.filters.plant) &&
      nextProps.form &&
      nextProps.form.properties &&
      nextProps.form.properties.formType
    ) {
      const defaultQuery = getSubmissionDefaultQuery(
        nextProps.form,
        nextProps.filters,
        nextProps.userForms,
        nextProps.form._id
      );
      nextProps.getSubmissions(1, { ...defaultQuery });

      return {
        ...prevState,
        filters: {
          ...nextProps.filters,
        },
      };
    } else return null;
  }

  dequeueOfflineSubmissions = (formId) => {
    const query = getQueryObject(this.props.filters, { limit: 999999 });
    this.props.getSubmissions(1, query);
    this.props.startSubmissionDequeuing();

    offlinePlugin
      .dequeueSubmissions(true, 0, true, (req) => {
        return (
          (req.data && req.type === "submission") ||
          (req.data && req.data.data) ||
          req.method === "DELETE"
        );
      })
      .finally(() => {
        const query = getQueryObject(this.props.filters, { limit: 999999 });
        this.props.getSubmissions(1, query);
        this.props.getSignaturesData(1, query);
        this.props.finishSubmissionDequeuing();
      });
  };

  componentDidUpdate() {
    const { auth } = this.props;

    this.operations = [
      {
        action: "view",
        buttonType: "primary",
        icon: "pencil",
        permissionsResolver() {
          return false;
        },
        title: "Enter Data",
      },
      {
        action: "submission",
        buttonType: "warning",
        icon: "list-alt",
        permissionsResolver() {
          return false;
        },
        title: "View Data",
      },
      {
        action: "edit",
        buttonType: "secondary",
        icon: "edit",
        permissionsResolver() {
          return false;
        },
        title: "Edit Form",
      },

      {
        action: "delete",
        buttonType: "light",
        icon: "trash",
        permissionsResolver() {
          return true;
        },
      },
    ];
  }

  getColumns = (form) => {
    const { location } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    let columns = [];

    const components =
      form.display === "wizard" &&
      qs.parse(location.search, { ignoreQueryPrefix: true }).page !== undefined
        ? form.components[currentPage].components
        : form.components;

    FormioUtils.eachComponent(components, (component) => {
      if (component.type === "datetime") {
        // the default accepted value for component is not 'new Date()', but rather 'moment()'
        // using this replacement to avoid warning.
        if (
          component.defaultDate &&
          component.defaultDate.includes("new Date()")
        )
          component.defaultDate = "moment()";
      }
      if (component.input && component.tableView && component.key) {
        columns.push(getComponentDefaultColumn(component));
      }
    });
    /* See the css changes needed
     */
    columns.length = 6;
    if (form._id !== Forms.Signature.id) {
      columns.length = 5;
    }
    setColumnsWidth(columns);
    columns = columns.map((column, index) => {
      if (column.key === "data.time") {
        column.width = 1;
      } else if (index === 1 || index === 0) {
        column.width = 3;
        if (form._id === Forms.Signature.id) {
          column.width = 2;
        }
      } else {
        column.width = 2;
      }
      return column;
    });

    return columns;
  };

  getCurrentPageSubmission(submissions) {
    const { location, filters, form, approverFlowCheck, auth } = this.props;
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);
    /*For forms with day level calculations, remove other shift data for display purpose alone in hourly tabs
    Such form tabs should have api key  have "dispLevel" with value "shiftLevel"  */
    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);

    const result = submissions.submissions.filter(
      (submission) =>
        (submission.metadata &&
          parseInt(submission.metadata.viewPage) === parseInt(currentPage) &&
          (form.components[currentPage].properties === undefined ||
            (form.components[currentPage].properties &&
              form.components[currentPage].properties.dispLevel ===
                undefined) ||
            (form.components[currentPage].properties &&
              form.components[currentPage].properties.dispLevel ===
                "shiftLevel" &&
              submission.data.shift === filters.shift)) &&
          !gpidSpecificView) ||
        (gpidSpecificView && submission.data.gpid === auth.user.data.gpid)
    );

    return {
      ...submissions,
      submissions: result,
    };
  }
  getPlantDetails(submissionsForGrid) {
    const filterValues =
      JSON.parse(window.sessionStorage.getItem("filters")) || {};
    const result = submissionsForGrid.submissions.filter(
      (submission) => submission.data.plantId == filterValues.plant
    );
    submissionsForGrid.submissions = result;
  }
  getMultiPlantDetails(submissionsForGrid) {
    const filterValues =
      JSON.parse(window.sessionStorage.getItem("filters")) || {};

    let result = submissionsForGrid.submissions.filter(
      ({ data }) =>
        (data.plant &&
          data.plant.length == 1 &&
          data.plant.includes(filterValues.plant)) ||
        (data.plantId &&
          data.plantId.length == 1 &&
          data.plantId.includes(filterValues.plant))
    );
    submissionsForGrid.submissions = result;
  }

  onSubmitForApprovalHandler = () => {
    const { goToSignaturePage } = this.props;

    goToSignaturePage();
  };

  onlineListener = () => {
    const {
      match: {
        params: { formId },
      },
      filters,
    } = this.props;

    const isOfflineSubmissionsForForm = isOfflineSubmissions(formId, filters);
    const isAbleToProcessQueue =
      offlinePlugin.submissionQueueLength() &&
      !offlinePlugin.dequeuing &&
      isOfflineSubmissionsForForm;

    // Dequeue Offline Submissions once app is back online
    if (isAbleToProcessQueue) {
      this.dequeueOfflineSubmissions(formId);
    }
  };

  getSubmissions = (page, query) => {
    const { form, userForms, filters } = this.props;

    const defaultQuery = getSubmissionDefaultQuery(
      form,
      filters,
      userForms,
      form._id
    );
    this.props.getSubmissions(page, { ...defaultQuery, ...query });
  };

  render() {
    const {
      form,
      isLoading,
      onAction,
      submissions,
      //  getSubmissions,
      errors,
      auth,
      match: {
        params: { formId },
      },
      location,
      languageParams: { language },
      signatures: { submissions: signatures },
      filters,
      userForms,
      approverFlowCheck,
    } = this.props;

    const isOfflineSubmissionsForForm = isOfflineSubmissions(formId, filters);

    if (
      formId === Forms.Signature.id &&
      approverFlowCheck &&
      !approverFlowCheck.approverView
    ) {
      this.props.setApprover();
    }
    const isAbleToProcessQueue =
      offlinePlugin.submissionQueueLength() &&
      !offlinePlugin.dequeuing &&
      isOfflineSubmissionsForForm;
    const isReadyToDeque = navigator.onLine && !isLoading;

    if (isAbleToProcessQueue && isReadyToDeque) {
      this.dequeueOfflineSubmissions(formId);
    }

    if (isLoading) {
      return (
        <div className="pep-c-loader">
          <div className="pep-c-iload"></div>
        </div>
      );
    }
    submissions.pagination = {};
    const currentPage = routeService.getCurrentPageNumberFromLocation(location);

    const submissionsForGrid =
      form.display === "wizard"
        ? this.getCurrentPageSubmission(submissions)
        : submissions;

    if (
      Forms.Signature.id === form._id &&
      submissionsForGrid !== undefined &&
      submissionsForGrid.submissions.length !== 0 &&
      filters.submitter >= 1000
    ) {
      const result = submissionsForGrid.submissions.filter(
        (submission) =>
          submission.data.gpid != undefined &&
          submission.data.gpid.toString().includes(filters.submitter.toString())
      );
      submissionsForGrid.submissions = result;
    }
    /*Admin form plant filter implemetation 
    displayPlantSubmissions & displayMultiPlantSubmissions API keys are used to indentify the Admin forms with filter*/

    if (form && form.properties && form.properties.displayPlantSubmissions) {
      this.getPlantDetails(submissionsForGrid);
    }

    if (
      form &&
      form.properties &&
      form.properties.displayMultiPlantSubmissions
    ) {
      this.getMultiPlantDetails(submissionsForGrid);
    }
    /*Admin form plant filter implemetation end*/
    //Check is signature is already done. If done , then restrict Add, Edit,Delete record features and disable the submit buttons
    let isBlocked = false;
    const gpidSpecificView = isGpidSpecific(form, approverFlowCheck);
    const gpidSpecificApproverView = isGpidSpecificApprover(
      form,
      approverFlowCheck
    );
    const specificGpid = gpidSpecificView
      ? auth.user.data.gpid
      : gpidSpecificApproverView
      ? filters.trainerForm
      : undefined;

    if (signatures) {
      isBlocked = signatures.some(
        (signature) =>
          signature.data.formId === form._id &&
          signature.data.formStatus &&
          signature.data.formStatus !== "" &&
          (!gpidSpecificView ||
            (gpidSpecificView && signature.data.gpid === auth.user.data.gpid))
      );
    }

    if (offlinePlugin.submissionQueue.length) {
      isBlocked = signatureOffline(form._id, this.props.filters, specificGpid);
    }
    if (userForms.departmentApprover && Forms.HrForms.id === form._id) {
      isBlocked = true;
    }

    if (
      approverFlowCheck &&
      approverFlowCheck.approverView &&
      isBlocked &&
      userForms &&
      userForms.approverForms &&
      userForms.approverForms.includes(formId)
    ) {
      isBlocked = false;
      if (!userForms.superApprover && signatures) {
        isBlocked = signatures.some(
          (signature) =>
            signature.data.formId === form._id &&
            signature.data.formStatus &&
            signature.data.formStatus === "Approved"
        );
      }
    }
    if (
      form &&
      ((form.type === "resource" &&
        (form._id === Forms.Line.id || form._id === Forms.Report.id)) ||
        (userForms.readOnlyForms &&
          userForms.readOnlyForms.length !== 0 &&
          userForms.readOnlyForms.includes(form._id)))
    ) {
      isBlocked = true;
    }

    const nodata = submissionsForGrid.submissions.length === 0;
    const annualForm =
      form && form.properties && form.properties.AnnualForm === true;
    const isReportPage =
      form &&
      form.components &&
      form.components[currentPage] &&
      form.components[currentPage].properties &&
      form.components[currentPage].properties.summary;
    const isBarCodePage =
      form &&
      form.components &&
      form.components[currentPage] &&
      form.components[currentPage].properties &&
      form.components[currentPage].properties.barCode;

    /**Filtering shiftlevel submissions for report view  starts */
    let summarySubmissions = {};
    if (isReportPage && submissions.length !== "0") {
      summarySubmissions = submissions.submissions
        .filter(
          (item) => item.metadata && parseInt(item.metadata.viewPage) !== 1
        )
        .map((item) => {
          return item;
        });
    } else {
      summarySubmissions = submissions.submissions;
    }
    /**Setting column and row data for CSV export-LN Code starts */

    let pageForm = form;
    const headerValue = [];
    const columnValue = [];
    let fileName = "Form.csv";
    if (
      form &&
      form._id === Forms.CSV.id &&
      submissionsForGrid.submissions &&
      submissionsForGrid.submissions.length !== 0 &&
      userForms &&
      userForms.forms.length !== 0
    ) {
      // (userForms.forms).map(function(elem) {
      userForms.forms.forEach(function (item) {
        if (item._id === submissionsForGrid.submissions[0].form) {
          pageForm = item;
        }
      });

      FormioUtils.eachComponent(pageForm.components, (component) => {
        if (component) {
          headerValue.push({
            label: i18next.t(component.label),
            key: component.key,
          });
        }
      });
      submissionsForGrid.submissions.forEach(function (item) {
        if (item.metadata) {
          Object.keys(item.metadata).forEach(function (keyValue) {
            const isKeyPresent = headerValue.some((el) => el.key === keyValue);
            if (
              !isKeyPresent &&
              (keyValue === "viewPage" ||
                keyValue === "tabName" ||
                keyValue === "userName" ||
                keyValue === "isOnline" ||
                keyValue === "recordType")
            ) {
              return headerValue.push({
                label: i18next.t(keyValue),
                key: keyValue,
              });
            }
          });
        }
      });
      // headerValue.push({
      //   label: 'SubmissionDateTime',
      //   key: 'submissionDateTime',
      // });
      submissionsForGrid.submissions.filter((item) => {
        item.data.viewPage =
          item.metadata && item.metadata.viewPage !== undefined
            ? item.metadata.viewPage
            : 0;
        item.data.date =
          item.data && item.data.date !== undefined
            ? item.data.date.split("T")[0]
            : 0;
        item.data.recordType =
          item.metadata && item.metadata.recordType !== undefined
            ? item.metadata.recordType
            : 0;
        item.data.isOnline =
          item.metadata && item.metadata.isOnline !== undefined
            ? item.metadata.isOnline
            : 0;
        item.data.tabName =
          item.metadata && item.metadata.tabName !== undefined
            ? item.metadata.tabName
            : 0;
        item.data.userName =
          item.metadata && item.metadata.userName !== undefined
            ? item.metadata.userName
            : 0;

        Object.keys(item.data).forEach(function (data) {
          // eslint-disable-next-line no-return-assign
          return (item.data[data] =
            item.data[data] !== "" &&
            item.data[data] !== undefined &&
            !Array.isArray(item.data[data])
              ? i18next.t(item.data[data])
              : item.data[data]);
        });
        // item.data = {
        //   ...item.data,
        //   submissionDateTime:item?.created
        // };
        return columnValue.push(item.data);
      });
      if (
        pageForm.formReference &&
        pageForm.formReference != undefined &&
        pageForm.formNameDescription &&
        pageForm.formNameDescription != undefined
      ) {
        fileName =
          pageForm.formReference +
          "-" +
          pageForm.formNameDescription.trim() +
          ".csv";
      } else {
        fileName = pageForm.name
          ? pageForm.name.trim() + ".csv"
          : pageForm.title.trim() + ".csv";
      }
    }

    if (
      filters.searchForm &&
      (submissionsForGrid.submissions.length !== 0 ||
        summarySubmissions.length !== 0)
    ) {
      submissionsForGrid.submissions = submissionsForGrid.submissions.filter(
        // eslint-disable-next-line
        function (form) {
          if (form.data) {
            // eslint-disable-next-line
            let data = JSON.stringify(form.data)
              .replace(/\"/g, "")
              .replace(/\,/g, "");

            if (
              normalizeDataSetCategory(data)
                .toLowerCase()
                .includes(
                  normalizeDataSetCategory(filters.searchForm).toLowerCase()
                )
            ) {
              return form;
            }
          }
        }
      );
    }
    if (gpidSpecificView || gpidSpecificApproverView) {
      const result = submissionsForGrid.submissions.filter((submission) =>
        submission.data.gpid.toString().includes(specificGpid)
      );
      submissionsForGrid.submissions = result;
    }

    /**Setting colum and row data for CSV export-LN Code ends */
    /**Filtering shifformtlevel submissions for report view  Ends */
    return (
      <div className="form-index">
        <Errors errors={errors} />
        {!isReportPage ? (
          !isBarCodePage ? (
            <div
              className={`pep-gridcon ${
                parseInt(currentPage) === 0 ? "pep-noborder" : ""
              }`}
            >
              <SubmissionGrid
                submissions={submissionsForGrid}
                form={form}
                columns={this.getColumns(pageForm)}
                onAction={(...args) => {
                  onAction(...args, isBlocked);
                }}
                operations={this.operations}
                getSubmissions={this.getSubmissions}
              />
            </div>
          ) : (
            <div>
              {/* <BarcodePicker/> */}
              <QuaggaBarCode />
            </div>
          )
        ) : (
          <div>
            <SummaryGrid submissions={summarySubmissions} form={form} />
          </div>
        )}

        <div className="grid-footer">
          <div className="pep-plusbtn">
            {isBlocked ||
            isReportPage ||
            isBarCodePage ||
            form._id === Forms.Signature.id ||
            form._id === Forms.CSV.id ||
            (form &&
              form.components &&
              form.components[parseInt(currentPage)] &&
              form.components[parseInt(currentPage)].properties &&
              form.components[parseInt(currentPage)].properties.display ===
                "commonForShift") ||
            (form &&
              form.properties &&
              form.properties.gpidSpecificForm &&
              approverFlowCheck &&
              approverFlowCheck.approverView) ? null : (
              <Link
                className="btn btn-primary btn-add-new"
                to={routeService.getPagePath.formPage(
                  language,
                  formId,
                  currentPage
                )}
              >
                <i
                  className="glyphicon glyphicon-plus fa fa-plus"
                  aria-hidden="true"
                />
                {i18next.t("", { title: i18next.t(form.title) })}
              </Link>
            )}
          </div>
          {/*To display submission records count in PreviewPage*/}
          {!isReportPage ? (
            <div className="submissionCount">
              {submissionsForGrid.submissions.length > 0
                ? submissionsForGrid.submissions.length
                : "No"}{" "}
              Records Found
            </div>
          ) : (
            ""
          )}

          {!isReportPage &&
          !isBarCodePage &&
          form._id !== Forms.Signature.id &&
          !(
            form &&
            form.components &&
            form.components[parseInt(currentPage)] &&
            form.components[parseInt(currentPage)].properties &&
            form.components[parseInt(currentPage)].properties.display ===
              "commonForShift"
          ) ? (
            <Legend items={this.state.legendItems} />
          ) : (
            ""
          )}
          {form &&
          form.components &&
          form.components[parseInt(currentPage)] &&
          form.components[parseInt(currentPage)].properties &&
          form.components[parseInt(currentPage)].properties.display ===
            "commonForShift" ? (
            <Legend items={this.state.conflictItems} />
          ) : (
            ""
          )}
          {form && form.properties && form.properties.csvExport ? (
            <Legend items={this.state.shiftItems} />
          ) : (
            ""
          )}
        </div>
        {!isReportPage && (
          <BottomControlButtons
            secondaryButtonText={
              parseInt(currentPage) === 0 &&
              approverFlowCheck &&
              approverFlowCheck.approverView
                ? i18next.t(PageTexts.BACK_TO_APPROVER)
                : i18next.t(PageTexts.BACK)
            }
            primaryButtonText={
              form._id === Forms.Signature.id ||
              (approverFlowCheck && approverFlowCheck.approverView)
                ? i18next.t(PageTexts.APPROVE_FORM)
                : i18next.t(PageTexts.SUBMIT_FOR_APPROVAL)
            }
            secondaryButtonPath={
              parseInt(currentPage) === 0
                ? approverFlowCheck && approverFlowCheck.approverView
                  ? routeService.getPagePath.approver(
                      language,
                      Forms.Signature.id
                    )
                  : routeService.getPagePath.formsList(language)
                : form &&
                  form.components &&
                  form.components[0] &&
                  form.components[0].properties &&
                  form.components[0].properties.display === "commonForShift"
                ? routeService.getPagePath.formPage(language, formId, 0)
                : routeService.getPagePath.submission(language, formId)
            }
            primaryButtonAction={this.onSubmitForApprovalHandler}
            isPrimaryButtonDisabled={
              isBlocked ||
              form._id === Forms.CSV.id ||
              form._id === Forms.Signature.id ||
              annualForm ||
              form.type === "resource"
            }
            csvColumnValue={columnValue}
            csvHeaderValue={headerValue}
            csvFileName={fileName}
            isCSVExport={
              form._id === Forms.CSV.id &&
              submissionsForGrid &&
              submissionsForGrid.submissions &&
              submissionsForGrid.submissions.length !== 0
            }
            currentPage={currentPage}
            nodata={nodata}
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const form = selectRoot("form", state);
  const submissions = selectRoot("submissions", state);
  const auth = selectRoot("auth", state);
  const filters = selectRoot("filters", state);
  const languageParams = selectRoot("languageParams", state);
  const signatures = selectRoot("signatures", state);
  const offlineQueue = selectRoot("offlineQueue", state);
  const userForms = selectRoot("userForms", state);

  const approverFlowCheck = selectRoot("approverFlowCheck", state);

  return {
    form: form.form,
    submissions: submissions,
    isLoading:
      form.isActive ||
      submissions.isActive ||
      signatures.isActive ||
      offlineQueue.dequeuing,
    auth,
    errors: [selectError("submissions", state), selectError("form", state)],
    filters,
    languageParams,
    signatures,
    userForms,
    approverFlowCheck,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { location } = ownProps;
  const currentPage = routeService.getCurrentPageNumberFromLocation(location);
  const language = routeService.getLanguageFromRoute();

  let formId = ownProps.match.params.formId;
  if (
    formId === Forms.CSV.id &&
    ownProps.filters.formName !== "" &&
    !isNaN(ownProps.filters.formName.charAt(0).replace(/['"]+/g, ""))
  ) {
    formId = ownProps.filters.formNameCsv;
  }

  return {
    getSubmissions: (page, query) =>
      dispatch(getSubmissions("submissions", page, query, formId)),

    getNewSubmission: () =>
      dispatch(
        push(routeService.getPagePath.formPagePrePopulate(language, formId, 0))
      ),
    getSignaturesData: (page, query) =>
      dispatch(getSubmissions("signatures", page, query, Forms.Signature.id)),
    onAction: (submission, action, isBlocked) => {
      if (ownProps.match.params.formId === Forms.CSV.id) {
        //No navigation in case of scv export page
      } else if (submission.form === Forms.Signature.id) {
        if (
          ownProps.userForms &&
          ownProps.userForms.forms &&
          ownProps.userForms.forms.length !== 0
        ) {
          const formDetails = ownProps.userForms.forms
            .filter((item) => item._id && item._id === submission.data.formId)
            .map((item) => {
              return item;
            });
          const filterValues =
            ownProps.headerFilterForm.data ||
            JSON.parse(window.sessionStorage.getItem("filters")) ||
            {};
          filterValues.line = submission.data.line;
          filterValues.shift = submission.data.shift;
          filterValues.date = submission.data.date;
          filterValues.trainerForm = "";
          if (
            formDetails &&
            formDetails[0].properties &&
            formDetails[0].properties.gpidSpecificForm
          ) {
            filterValues.trainerForm = submission.data.gpid;
          }

          window.sessionStorage.setItem(
            "filters",
            JSON.stringify(filterValues)
          );
          dispatch(setFiltersValue(filterValues));
          if (
            formDetails.length !== 0 &&
            formDetails[0].components[0] &&
            formDetails[0].components[0].properties &&
            formDetails[0].components[0].properties.display &&
            formDetails[0].components[0].properties.display === "commonForShift"
          ) {
            dispatch(
              push(
                routeService.getPagePath.formPage(
                  language,
                  submission.data.formId,
                  0
                )
              )
            );
          } else {
            dispatch(
              push(
                routeService.getPagePath.submission(
                  language,
                  submission.data.formId
                )
              )
            );
          }
        } else {
          dispatch(
            push(
              routeService.getPagePath.approver(
                language,
                submission.data.formId
              )
            )
          );
        }
      } else {
        switch (action) {
          case "view":
          case "row":
          case "edit":
            dispatch(
              push(
                routeService.getPagePath.editSubmission(
                  language,
                  formId,
                  submission._id,
                  currentPage
                )
              )
            );
            break;
          case "delete":
            dispatch(
              push(
                routeService.getPagePath.deleteSubmission(
                  language,
                  formId,
                  submission._id,
                  currentPage
                )
              )
            );
            break;
          default:
        }
      }
    },
    goToSignaturePage: () => {
      dispatch(push(routeService.getPagePath.signature(language, formId)));
    },
    setApprover: () => dispatch(setApproverFlow()),
    startSubmissionDequeuing: () => dispatch(startSubmissionsDequeuing()),
    finishSubmissionDequeuing: () => dispatch(finishSubmissionsDequeuing()),
  };
};

List.propTypes = {
  auth: PropTypes.object,
  errors: PropTypes.any,
  match: PropTypes.object,
  languageParams: PropTypes.object,
  otherformData: PropTypes.object,
  isBlocked: PropTypes.bool,
  signatures: PropTypes.object,
  location: PropTypes.object,
  filters: PropTypes.object,
  submissions: PropTypes.object,
  hideComponents: PropTypes.object,
  signature: PropTypes.object,
  options: PropTypes.object,
  getSignaturesData: PropTypes.func,
  getNewSubmission: PropTypes.func,
  getSubmissions: PropTypes.func,
  resetSubmission: PropTypes.func,
  getSubmission: PropTypes.func,
  submission: PropTypes.object,
  getForm: PropTypes.func,
  onSubmit: PropTypes.func,
  goToSignaturePage: PropTypes.func,
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  startSubmissionDequeuing: PropTypes.func,
  finishSubmissionDequeuing: PropTypes.func,
  onAction: PropTypes.func,
  approverView: PropTypes.object,
  approverForms: PropTypes.object,
  userForms: PropTypes.object,
  approverFlowCheck: PropTypes.object,
  setApprover: PropTypes.func,
  isPrimaryButtonDisabled: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(List);
